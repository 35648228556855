<template>
  <teleport to="head">
    <title>
      Услуги в Ореховском
    </title>
  </teleport>
  <main class="services main page__view">
    <h2 class="services__header-text header-text ">НАШИ УСЛУГИ</h2>
    <ServiceCardList :services="services" />
  </main>
</template>

<script>
import { mapState } from 'vuex';
import ServiceCardList from '@/components/ServiceCardList.vue';
export default {
  computed: {
    ...mapState(['services']),
  },
  components: {
    ServiceCardList,
  },
};
</script>

<style scoped>
/* Services */
.services.main {
  padding: 20px;
}

.services__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 30px;
}

@media (max-width: 950px) {
  .services__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 680px) {
  .services__list {
    grid-template-columns: 1fr;
  }
}

/* Service */
.service {
  background-image: url('/images/random_grey_variations.png');
  padding: 50px 10px;
  text-align: center;
  display: grid;
  row-gap: 15px;
  color: white;
  border-radius: 5px;
}

.service__image {
  width: 70%;
  justify-self: center;
}

.services__item {
  list-style: none;
}

.service__price {
  font-size: 1.6em;
}

.service__additional-info,
.service__see-more {
  font-size: 1.4em;
}

/* See More Button */
.service__see-more {
  width: 60%;
  justify-self: center;
  padding: 10px 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 4px;
}
/* Service Image */
</style>
